<template>
  <div>
    <div class="wrapper">
      <div class="reset__inner">
        <img src="@/assets/images/logo-dark.svg" alt="" />
        <VRow align="center" justify="center">
          <VCard width="600">
            <VCardTitle class="d-flex justify-center">Зміна паролю</VCardTitle>
            <div></div>
            <VCardText class="mt-5">
              <VRow align="center" justify="center">
                <VCol cols="5">
                  <VTextField
                    outlined
                    autocomplete="new-password"
                    label="Новий пароль"
                    dense
                    v-model="passForm.newPass"
                    :type="newPasswordVisible ? 'text' : 'password'"
                    :append-icon="newPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="newPasswordVisible = !newPasswordVisible"
                    :error-messages="newPassErrors"
                    :hide-details="!newPassErrors.length"
                  />
                </VCol>
              </VRow>
              <VRow align="center" justify="center">
                <VCol cols="5">
                  <VTextField
                    outlined
                    autocomplete="new-password"
                    label="Підтвердіть пароль"
                    dense
                    :hide-details="!newPassRepeatErrors.length"
                    v-model="passForm.newPassRepeat"
                    :type="repeatPasswordVisible ? 'text' : 'password'"
                    :append-icon="repeatPasswordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="repeatPasswordVisible = !repeatPasswordVisible"
                    :error-messages="newPassRepeatErrors"
                  />
                  <VBtn block color="success" @click="ChangePass">
                    Змінити пароль
                  </VBtn>
                </VCol>
              </VRow>
            </VCardText>
          </VCard>
        </VRow>
      </div>
    </div>
  </div>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import usersService from '../../services/request/users/usersService';
import notifications from '../../mixins/notifications';
import loader from '../../mixins/loader';
import language from '../../mixins/language';
import passwordMask from '../../validators/passwordMask';

export default {
  mixins: [notifications, loader, validationMixin, language, loader],

  validations: {
    passForm: {
      newPass: { required, passwordMask },
      newPassRepeat: { required, sameAsPassword: sameAs('newPass') },
    },
  },
  data: () => ({
    currentPasswordVisible: false,
    newPasswordVisible: false,
    repeatPasswordVisible: false,
    passForm: {
      newPass: '',
      newPassRepeat: '',
    },
  }),

  computed: {
    newPassErrors() {
      const errors = [];
      if (!this.$v.passForm.newPass.$dirty) {
        return errors;
      }
      if (!this.$v.passForm.newPass.passwordMask) {
        errors.push(this.language.PASSWORD_MUST_BE_LIKE);
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.passForm.newPass.required && errors.push(this.language.isRequired('Новий пароль'));
      return errors;
    },
    newPassRepeatErrors() {
      const errors = [];
      if (!this.$v.passForm.newPassRepeat.$dirty) {
        return errors;
      }
      if (!this.$v.passForm.newPassRepeat.required) {
        errors.push(this.language.isRequired('Підтвердіть пароль'));

        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.passForm.newPassRepeat.sameAsPassword && errors.push('Паролі не збігаються');
      return errors;
    },
  },

  methods: {
    async ChangePass() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.setLoading(true);
          const formDataPass = new FormData();
          formDataPass.append('hash', this.$route.params.hash);
          formDataPass.append('password_new', this.passForm.newPass);
          formDataPass.append('password_confirm', this.passForm.newPassRepeat);
          await usersService.userPasswordReset(formDataPass);
          // orderDialog("pass-respons", response);
          this.setSuccessNotification('Пароль успішно змінено');
          this.$router.push({ name: 'login' });
          this.setLoading(false);
        } catch (e) {
          this.setLoading(false);
          this.setErrorNotification('Помилка зміни паролю, пароль вже використовується');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f8f8f8;
  position: relative;
  img {
    position: absolute;
    top: 50px;
    width: 200px;
  }
  .reset__inner {
    position: relative;
    width: 800px;
    height: 614px;
    background: #fff;
    background-image: url('../../assets/images/Frame.svg');
    background-size: contain;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: 50%;
      margin-top: 50px;
    }
    p {
      &:nth-child(2) {
        margin-top: 50px;
      }
    }
    h2 {
      font-size: 12px;
      font-weight: 300;
      margin-top: 150px;
    }
  }
}
</style>
